import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import '@/helpers/style.scss'
import { abilitiesPlugin } from '@casl/vue';
import { Ability } from '@casl/ability';

const ability = new Ability();

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
 
// import 'ag-grid-community';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';

import '@/helpers/ag-grid-enterprise';
import '@/helpers/ag-grid-enterprise/styles/ag-grid.css';
import '@/helpers/ag-grid-enterprise/styles/ag-theme-alpine.css';

import { AgGridVue } from 'ag-grid-vue3'; 

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import flatPickr from "vue-flatpickr-component";
import { CountTo } from "vue3-count-to";
import Lottie from "@/components/widgets/lottie";
import { VueEcharts } from "vue3-echarts";

import vueFilePond from 'vue-filepond';
// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';

// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

// Create FilePond component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

import { formatDateTime, formatAmount, formatFourDecimal, formatAsMoney, checkAvailability } from '@/helpers/utilities';

const app = createApp(App);

app.config.globalProperties.$formatDateTime = formatDateTime;
app.config.globalProperties.$formatAmount = formatAmount;
app.config.globalProperties.$formatFourDecimal = formatFourDecimal;
app.config.globalProperties.$formatAsMoney = formatAsMoney;

app.config.globalProperties.$checkAvailability = checkAvailability;

// FakeBackend authentication
// import { configureFakeBackend } from './helpers/fake-backend';
// configureFakeBackend();


// Firebase authentication
// import { initFirebaseBackend } from './authUtils';

// const firebaseConfig = {
//     apiKey: process.env.VUE_APP_APIKEY,
//     authDomain: process.env.VUE_APP_AUTHDOMAIN,
//     databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//     projectId: process.env.VUE_APP_PROJECTId,
//     storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//     messagingSenderId: process.env.VUE_APP_MEASUREMENTID
// };

// initFirebaseBackend(firebaseConfig);
 
AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

app
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(i18n)
    .use(vClickOutside)
    .use(abilitiesPlugin, ability, {
        useGlobalProperties: true
    })
    .component('Layout', Layout)
    .component('PageHeader', PageHeader)
    
    .component('AgGridVue', AgGridVue)
    .component('Vue3EasyDataTable', Vue3EasyDataTable)
    .component('flatPickr', flatPickr)
    .component('CountTo', CountTo)
    .component('Lottie', Lottie)
    .component('VueEcharts', VueEcharts)
    .component('FilePond', FilePond)
    .component('Multiselect', Multiselect)
    .mount('#app');
<script>
export default {
    data() {
        return {
            // imageUrl: "/images/aai.png",
        }
    },
    props: {
        size: {
            type: String,
            default: 'lg',
        },
        imageUrl: {
            type: String,
            default: "/images/aai.png"
        }
    },
    // created(){
    //     console.log(this.imageUrl, 'test');
    // }
}
</script>
<template>
    <img :src="imageUrl" :style="'height:' + (size == 'sm' ? '18' : '40') + 'px;'" />
</template>

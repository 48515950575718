<script>
import {
  layoutComputed
} from "@/state/helpers";
import {
  SimpleBar
} from "simplebar-vue3";
import { fetchMenus } from "@/helpers/menuApi.js"
export default {
  components: {
    SimpleBar
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
      // menus: ,
    };
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
    },
    menus() {
      return this.$store.state.menus;
    }
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  methods: {
    loadMenu() {
      if (localStorage.getItem('storedMenu') == null || localStorage.getItem('storedMenu').length===0)
      {
        fetchMenus();
      }
      else
      {
        this.$store.commit('getMenus');
      }
    },
    
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
      }
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');
          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                  parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
                const grandparent = parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.parentElement.closest(".collapse");
                if (grandparent && grandparent && grandparent.previousElementSibling) {
                  grandparent.previousElementSibling.classList.add("active");
                  grandparent.classList.add("show");
                }
              }
            }
          }
        }
      }, 0);
    },
  },
  created() {
    
  },
  mounted() {
    this.loadMenu();
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");

      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                siblingCollapse.classList.remove("show");
                siblingCollapse.parentElement.firstChild.setAttribute("aria-expanded", "false");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2) {
                item.firstElementChild.setAttribute("aria-expanded", "false");
                item.firstElementChild.classList.remove("active");
              }
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                item1.classList.remove("show");
                item1.parentElement.firstChild.setAttribute("aria-expanded", "false");
                item1.parentElement.firstChild.classList.remove("active");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.classList.remove("show");
            childCollapseInstance.parentElement.firstChild.setAttribute("aria-expanded", "false");
          });
        });
      });
    }
  },

 
};
</script>

<template>
  <b-container fluid>
    <div id="two-column-menu"></div>

    <template v-if="layoutType === 'twocolumn'">
      <SimpleBar class="navbar-nav" id="navbar-nav">
        <li class="menu-title">
          <span data-key="t-menu"> {{ $t("t-menu") }}</span>
        </li>
        <li class="nav-item" v-for="(menu, index) in menus" :key="index">
          <b-link v-if="menu.child" class="nav-link menu-link" :href="'#menu'+index" data-bs-toggle="collapse" role="button"
            aria-expanded="false">
            <i v-if="menu.parent.icon" :class="menu.parent.icon"></i>
            <span> {{ $t(menu.parent.name) }}</span>
          </b-link>
          <div v-if="menu.child" class="collapse menu-dropdown" :id="'menu'+index">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item" v-for="(child, i) in menu.child" :key="i">
                <router-link :to="child.url" class="nav-link custom-abc">
                  {{ $t(child.menu_name) }}
                </router-link>
              </li>
            </ul>
          </div>
          <div v-else>
            <router-link v-if="menu.parent.url" :to="menu.parent.url" class="nav-link custom-abc">
              <i v-if="menu.parent.icon" :class="menu.parent.icon"></i>
              <span> {{ $t(menu.parent.name) }}</span>
            </router-link>
            <router-link v-else href="javascript: void(0)" class="nav-link custom-abc">
              <i v-if="menu.parent.icon" :class="menu.parent.icon"></i>
              <span> {{ $t(menu.parent.menu_name) }}</span>
            </router-link>
          </div>
        </li>
      </SimpleBar>
    </template>

    <template v-else>
      <ul class="navbar-nav h-100" id="navbar-nav">
        <li class="menu-title">
          <span data-key="t-menu"> {{ $t("t-menu") }}</span>
        </li>

        <template v-for="(menu, index) in menus" :key="index">

          <li v-if="menu.children.length == 0" class="nav-item">
            <router-link v-if="menu.url" :to="menu.url" class="nav-link" :data-key="menu.menu_name">
              <i v-if="menu.icon" :class="menu.icon"></i>
              <span :data-key="menu.menu_name"> {{ $t(menu.menu_name) }}</span>
            </router-link>
          </li>
 
          <li v-else class="nav-item">
            <b-link 
              class="nav-link menu-link" 
              :href="'#menu'+index" 
              data-bs-toggle="collapse" 
              role="button"
              aria-expanded="false" 
              :aria-controls="'menu'+index">
  
              <i v-if="menu.icon" :class="menu.icon"></i>
              <span :data-key="menu.menu_name"> {{ $t(menu.menu_name) }}</span>
            </b-link>

            <div class="collapse menu-dropdown" :id="'menu'+index">
              <ul class="nav nav-sm flex-column">

                <template v-for="(child, child_index) in menu.children" :key="child_index">

                  <li v-if="child.subchildren.length == 0" class="nav-item">
                    <router-link v-if="child.url" :to="child.url" class="nav-link" :data-key="child.menu_name">
                      {{ $t(child.menu_name) }}
                    </router-link>
                  </li>
                
                  <li v-else class="nav-item">
                    <b-link :href="'#child'+child_index" class="nav-link" data-bs-toggle="collapse" role="button"
                      aria-expanded="false" :aria-controls="'child'+child_index" :data-key="child.menu_name">
                      {{ $t(child.menu_name) }}
                    </b-link>

                    <div class="collapse menu-dropdown" :id="'child'+child_index">
                      <ul class="nav nav-sm flex-column">

                        <template v-for="(sub_child, sub_child_index) in child.subchildren" :key="sub_child_index">

                          <li v-if="sub_child.grandchildren.length == 0" class="nav-item">
                            <router-link v-if="sub_child.url" :to="sub_child.url" class="nav-link" :data-key="sub_child.menu_name">
                              {{ $t(sub_child.menu_name) }}
                            </router-link>
                          </li>

                          <li v-else class="nav-item">
                            <b-link :href="'#sub_child'+sub_child_index" class="nav-link" data-bs-toggle="collapse" role="button"
                              aria-expanded="false" :aria-controls="'sub_child'+sub_child_index" :data-key="sub_child.menu_name">
                              {{ $t(sub_child.menu_name) }}
                            </b-link>
                            <div class="collapse menu-dropdown" :id="'sub_child'+sub_child_index">
                              <ul class="nav nav-sm flex-column">
                          
                                <li class="nav-item" v-for="(grand_child, grand_child_index) in sub_child.grandchildren" :key="grand_child_index">
                                  <router-link v-if="grand_child.url" :to="grand_child.url" class="nav-link" :data-key="grand_child.menu_name">
                                    {{ $t(grand_child.menu_name) }}
                                  </router-link>
                                </li>

                              </ul>
                            </div>
                          </li>

                        </template>

                      </ul>
                    </div>
                  </li>

                </template> 

              </ul>
            </div>
          </li>

        </template> 
      </ul>
    </template>
</b-container>
</template>
<script>
import { layoutComputed } from "@/state/helpers";

import Vertical from "./vertical";
import Horizontal from "./horizontal";
import TwoColumns from "./twocolumn";
import { ABILITY_TOKEN } from '@casl/vue';
import Swal from "sweetalert2";
import axios from "axios";

export default {
    inject: {
        $ability: { from: ABILITY_TOKEN }
    },
    components: {
        Vertical,
        Horizontal,
        TwoColumns
    },
    props: {
        permissions: {
            type: Array
        },
        module: {
            type: String
        }

    },
    data() {
        return {
            hasPermission: true,
        };
    },
    methods: {
        checkPageAccess() {
            this.hasPermission = false;
            if(this.permissions) {
                this.permissions.forEach(perm => {
                    if(this.$ability.can(perm, this.module)) this.hasPermission = true;
                })
            } else {
                this.defaultPermissions.forEach(perm => {
                    if(this.$ability.can(perm, this.module)) this.hasPermission = true;
                })
            }
            
            if(!this.hasPermission) {
                Swal.fire({
                    title: "Access denied!",
                    text: "You do not have permission on this module.",
                    icon: "error",
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Ok",
                }).then(() => {
                    this.$router.go(-1);
                    // if(history.previous !== undefined) {
                    // } else {
                    //     this.$router.push({ name: 'default' });
                    // }
                });
            }
        },
        async checkTokenExpired() {
            const userId = localStorage.getItem('userid');
            if (userId) {
            await axios.get(`${process.env.VUE_APP_AUTHDOMAIN}/api/v1/users/${userId}`)
            .then((response) => {
                if(!response.data.data) {
                    this.logout();
                }
            }).catch(() => {
                this.logout();
            });
            }
        },
        logout() {
            localStorage.clear();
            this.$router.push({name: 'login'});
        },

    },
    computed: {
        ...layoutComputed,
        defaultPermissions() {
            return this.$store.state.permissions || [];
        }
    },
    mounted() {
        this.checkTokenExpired();
        if(this.module) {
            this.checkPageAccess();
        }
        // document.querySelector("html").setAttribute('dir', 'rtl');
    }
};
</script>

<template>
    <div>
        <Vertical v-if="layoutType === 'vertical' || layoutType === 'semibox'" :layout="layoutType">
            <slot v-if="hasPermission" />
        </Vertical>

        <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
            <slot v-if="hasPermission"/>
        </Horizontal>

        <TwoColumns v-if="layoutType === 'twocolumn'" :layout="layoutType">
            <slot v-if="hasPermission"/>
        </TwoColumns>
    </div>
</template>

import moment from 'moment';
import axios from 'axios';

export function formatAmount(amount) {
  if (typeof amount !== 'undefined') {
    if (typeof amount !== 'string') {
      amount = amount.toString();
    }
  } else {
    amount = '0.00'; 
  }

  const cleanedAmount = amount.replace(/,/g, '');

  if (isNaN(cleanedAmount) || cleanedAmount == "") {
    return '0.00'; 
  } 

  let formattedAmount = parseFloat(cleanedAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  if (formattedAmount.includes('-')) {
    formattedAmount = `(${formattedAmount.replace('-', '')})`;
  }

  return formattedAmount;
}

export function formatFourDecimal(amount) {
  if (typeof amount !== 'undefined') {
    if (typeof amount !== 'string') {
      amount = amount.toString();
    }
  } else {
    amount = '0.00'; 
  }

  const cleanedAmount = amount.replace(/,/g, '');

  if (isNaN(cleanedAmount) || cleanedAmount == "") {
    return '0.00'; 
  } 

  let formattedAmount = parseFloat(cleanedAmount).toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 });

  if (formattedAmount.includes('-')) {
    formattedAmount = `(${formattedAmount.replace('-', '')})`;
  }

  return formattedAmount;
}

// import moment

export function formatDate(date, format = 'YYYY-MM-DD') {
  return moment(date).format(format);
}

export function formatTime(time, format = 'HH:mm:ss') {
  return moment(time).format(format);
}

export function formatDateTime(datetime, format = 'YYYY-MM-DD HH:mm') {
  return moment(datetime).format(format);
}

export function formatAsMoney(params) {
  if (params && params.value != null) {
    let value = params.value;
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    if (!isNaN(value)) {
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
  }
  return '0.00';
}

export function formatFourAsMoney(params) {
  if (params && params.value != null) {
    let value = params.value;
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    if (!isNaN(value)) {
      return value.toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
  }
  return '0.00';
}

export async function checkAvailability(data) {
  try {
    const response = await axios.get(`${process.env.VUE_APP_AUTHDOMAIN}/api/v1/check-exists`, { 
      params: {
        t: data.t,
        c: data.c,
        v: data.v,
        ci: data.ci,
        civ: data.civ,
      }
    });
    return response.data !== 0;
  } catch (error) {
    throw new Error("Error checking value availability.");
  }
}